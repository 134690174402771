// classes need to align with TENANT_IDs
.EDI {
  --primary-font: 'Polo11-Krftg';
  --backup-font: 'sans-serif';
  --tertiary-font: 'Polo11-Buch';
  --secondary-font: 'Polo11-Leicht';

  // Font
  --page-title: 34px var(--primary-font), var(--backup-font);
  --sub-title: 24px var(--primary-font), var(--backup-font);
  --regular-text: 16px var(--secondary-font), var(--backup-font);
  --fine-print: 12px var(--secondary-font), var(--backup-font);
  --strong-fine-print: 12px var(--primary-font), var(--backup-font);
  --button-text: 14px var(--primary-font), var(--backup-font);
  --special-text: 16px var(--primary-font), var(--backup-font);
  --special-text-tertiary: 17px var(--tertiary-font), var(--backup-font);
  --header-text: 16px var(--primary-font), var(--backup-font);
  --hamburger-menu-text: 12px var(--secondary-font), var(--backup-font);
  --headline-text: 44px var(--primary-font), var(--backup-font);
  --sub-headline-text: 18px var(--secondary-font), var(--backup-font);
  --event-list-group-info-subtitle: 18px var(--primary-font), var(--backup-font);
  --event-list-group-date-text: 16px var(--primary-font), var(--backup-font);
  --event-list-group-special-text: 16px var(--tertiary-font), var(--backup-font);
  --footer-navigation-text: 18px var(--primary-font), var(--backup-font);
  --footer-text: 18px var(--secondary-font), var(--backup-font);
  --footer-last-text: 18px var(--secondary-font), var(--backup-font);
  --error-message-text: 12px var(--secondary-font), var(--backup-font);
  --table-header-text: 14px var(--primary-font), var(--backup-font);
  --section-subtitle-text: 18px var(--primary-font), var(--backup-font);
  --strong-regular-text: 16px var(--primary-font), var(--backup-font);
  --steps-number: 14px var(--primary-font), var(--backup-font);

  // Colors
  --primary-color: #145f8c;
  --secondary-color: #3eb14d;
  --primary-text-color-footer: #fff;
  --primary-color-footer: #145f8c;
  --secondary-color-footer: #024f7d;
  --secondary-text-color-footer: #fff;
  --home-page-color: #3eb14d;
  --home-page-sublink-color: #3fb24d;
  --card-link-color: #145f8c;
  --action-button-inverted-active-color: #1874ab;
  --pre-selection-separator-color: #fff;
  --primary-light-color: #1874ab0d;

  // Layout
  --footer-text-spacing: 0px;
}

.SHNG {
  --primary-font: 'Polo11-Krftg';
  --backup-font: 'sans-serif';
  --tertiary-font: 'Polo11-Buch';
  --secondary-font: 'Polo11-Leicht';

  // Font
  --page-title: 34px var(--primary-font), var(--backup-font);
  --sub-title: 24px var(--primary-font), var(--backup-font);
  --regular-text: 16px var(--secondary-font), var(--backup-font);
  --fine-print: 14px var(--secondary-font), var(--backup-font);
  --strong-fine-print: 14px var(--primary-font), var(--backup-font);
  --button-text: 14px var(--primary-font), var(--backup-font);
  --special-text: 16px var(--primary-font), var(--backup-font);
  --special-text-tertiary: 17px var(--tertiary-font), var(--backup-font);
  --header-text: 16px var(--primary-font), var(--backup-font);
  --hamburger-menu-text: 12px var(--secondary-font), var(--backup-font);
  --headline-text: 44px var(--primary-font), var(--backup-font);
  --sub-headline-text: 18px var(--secondary-font), var(--backup-font);
  --event-list-group-info-subtitle: 18px var(--primary-font), var(--backup-font);
  --event-list-group-date-text: 16px var(--primary-font), var(--backup-font);
  --event-list-group-special-text: 16px var(--tertiary-font), var(--backup-font);
  --footer-navigation-text: 18px var(--primary-font), var(--backup-font);
  --footer-text: 18px var(--secondary-font), var(--backup-font);
  --footer-last-text: 18px var(--secondary-font), var(--backup-font);
  --error-message-text: 12px var(--secondary-font), var(--backup-font);
  --table-header-text: 14px var(--primary-font), var(--backup-font);
  --section-subtitle-text: 18px var(--primary-font), var(--backup-font);
  --strong-regular-text: 16px var(--primary-font), var(--backup-font);
  --steps-number: 14px var(--primary-font), var(--backup-font);

  // Colors
  --primary-color: #0080c8;
  --secondary-color: #97c3ea;
  --primary-text-color-footer: #fff;
  --primary-color-footer: #50aff5;
  --secondary-color-footer: #0080c8;
  --secondary-text-color-footer: #fff;
  --home-page-color: #50aff5;
  --home-page-sublink-color: #50aff5;
  --card-link-color: #0080c8;
  --action-button-inverted-active-color: #0094e7;
  --pre-selection-separator-color: #fff;
  --primary-light-color: #50aff50d;

  // Layout
  --footer-text-spacing: 20px;
}

.AVA {
  // Font family
  --primary-font: 'EONBrixSansBlack';
  --backup-font: 'arial';
  --tertiary-font: 'EONBrixSansRegular';
  --secondary-font: 'EONBrixSansRegular';

  // Font
  --page-title: 38px var(--primary-font), var(--backup-font);
  --sub-title: 24px var(--primary-font), var(--backup-font);
  --regular-text: 16px var(--secondary-font), var(--backup-font);
  --fine-print: 12px var(--secondary-font), var(--backup-font);
  --strong-fine-print: 12px var(--primary-font), var(--backup-font);
  --button-text: 16px var(--primary-font), var(--backup-font);
  --special-text: 20px var(--primary-font), var(--backup-font);
  --special-text-tertiary: 17px var(--tertiary-font), var(--backup-font);
  --header-text: 16px var(--primary-font), var(--backup-font);
  --hamburger-menu-text: 12px var(--secondary-font), var(--backup-font);
  --headline-text: 48px var(--primary-font), var(--backup-font);
  --sub-headline-text: inherit;
  --event-list-group-info-subtitle: 18px var(--primary-font), var(--backup-font);
  --event-list-group-date-text: 16px var(--primary-font), var(--backup-font);
  --event-list-group-special-text: 16px var(--tertiary-font), var(--backup-font);
  --footer-navigation-text: 18px var(--primary-font), var(--backup-font);
  --footer-text: 18px var(--secondary-font), var(--backup-font);
  --footer-last-text: 18px var(--primary-font), var(--backup-font);
  --error-message-text: 13px var(--secondary-font), var(--backup-font);
  --table-header-text: 16px var(--primary-font), var(--backup-font);
  --section-subtitle-text: 20px var(--primary-font), var(--backup-font);
  --strong-regular-text: 16px var(--primary-font), var(--backup-font);
  --steps-number: 14px var(--primary-font), var(--backup-font);

  // Colors
  --primary-color: #005564;
  --secondary-color: #97c3ea;
  --primary-text-color-footer: #005554;
  --primary-color-footer: #86bc25;
  --secondary-color-footer: #fff;
  --secondary-text-color-footer: #767676;
  --home-page-color: #86bc25;
  --home-page-sublink-color: #86bc25;
  --card-link-color: #86bc25;
  --action-button-inverted-active-color: #97d42a;
  --pre-selection-separator-color: #86bc25;
  --contact-persons-color: rgb(0, 85, 100);
  --contact-persons-green-color: rgba(134, 188, 37);
  --primary-light-color: #38b6ab0d;

  // Layout
  --footer-text-spacing: 0px;
}

.BAG {
  // Font family
  --primary-font: 'EONRegularFont';
  --backup-font: 'arial';
  --tertiary-font: 'Polo11-Buch';
  --secondary-font: 'Polo11-Leicht';

  // Font
  --page-title: 38px var(--primary-font), var(--backup-font);
  --sub-title: 24px var(--primary-font), var(--backup-font);
  --regular-text: 16px var(--secondary-font), var(--backup-font);
  --fine-print: 12px var(--secondary-font), var(--backup-font);
  --strong-fine-print: 12px var(--primary-font), var(--backup-font);
  --button-text: 16px var(--secondary-font), var(--backup-font);
  --special-text: 20px var(--primary-font), var(--backup-font);
  --special-text-tertiary: 17px var(--tertiary-font), var(--backup-font);
  --header-text: 16px var(--secondary-font), var(--backup-font);
  --hamburger-menu-text: 12px var(--secondary-font), var(--backup-font);
  --headline-text: 48px var(--primary-font), var(--backup-font);
  --sub-headline-text: inherit;
  --event-list-group-info-subtitle: 18px var(--tertiary-font), var(--backup-font);
  --event-list-group-date-text: 16px var(--primary-font), var(--backup-font);
  --event-list-group-special-text: 16px var(--tertiary-font), var(--backup-font);
  --footer-navigation-text: 18px var(--secondary-font), var(--backup-font);
  --footer-text: 18px var(--secondary-font), var(--backup-font);
  --footer-last-text: 18px var(--primary-font), var(--backup-font);
  --error-message-text: 13px var(--secondary-font), var(--backup-font);
  --table-header-text: 16px var(--primary-font), var(--backup-font);
  --section-subtitle-text: 18px var(--primary-font), var(--backup-font);
  --strong-regular-text: 16px var(--tertiary-font), var(--backup-font);
  --steps-number: 14px var(--primary-font), var(--backup-font);

  // Colors
  --primary-color: #0091bb;
  --secondary-color: #ffe100;
  --primary-text-color-footer: #fff;
  --primary-color-footer: #42b0d2;
  --secondary-color-footer: #0091bb;
  --secondary-text-color-footer: #fff;
  --home-page-color: #ffe100;
  --home-page-sublink-color: #ffe100;
  --card-link-color: #0091bb;
  --action-button-inverted-active-color: #42b0d2;
  --pre-selection-separator-color: #ffe100;
  --primary-light-color: #0094e70d;

  // Layout
  --footer-text-spacing: 0px;
}
