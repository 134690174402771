@import "../../global/style/variables.scss"
.test-container
    width: 100%
    .test-alert
        text-align: left
        color: var(--black-color)
        width: 100%
        background: var(--mercury-color)
        padding: $spacing
        display: flex
        flex-direction: column
        gap: $spacing
    .test-alert-bold
        font: var(--strong-regular-text)
