@import '../../../global/style/variables'
.event-meta-data
    display: flex
    flex-direction: column
    gap: $spacing
    &__description
    &__header
        display: flex
        flex-direction: row
    &__header-date
        font: var(--strong-fine-print)
        margin-right: $spacing / 2
    &__informations
        display: flex
        justify-content: space-between
    &__column
        display: flex
        flex-direction: column
        gap: $spacing
        width: 50%
    &__section
        display: flex
        gap: $spacing / 2
    &__icon-container
        width: 20px
        height: fit-content
    &__icon
        fill: var(--grey-color)
        color: var(--grey-color)
        &__link
            fill: var(--link-color)
            color: var(--link-color)
    &__text-container
        display: flex
        flex-direction: column
        word-break: break-word
        font: var(--regular-text)
        &__horizontal
            display: flex
            flex-direction: row
            gap: $spacing / 4
            flex-wrap: wrap
            word-break: break-word
            font: var(--regular-text)
        &__text-strong
            font: var(--strong-regular-text)
        &__text-subtitle
            font: var(--event-list-group-info-subtitle)
    &__item-link
        cursor: pointer
        text-decoration: underline
        font: var(--event-list-group-special-text)
        color: var(--link-color)
        &:link
            text-decoration: underline
            font: var(--event-list-group-special-text)
            color: var(--link-color)

        &:visited
            font: var(--event-list-group-special-text)
            color: var(--link-color)
            text-decoration: underline
    &__title
        word-wrap: break-word
        font: var(--page-title)
        padding-top: $spacing / 2
        padding-bottom: $spacing / 2
